<template>
	<li class="footer-section-item">
		<div class="footer-section-info d-flex align-items-center">
			<span data-test-id="footer-section-name" class="footer-section-name typo-body-2">{{ name }}</span>
		</div>
		<div class="footer-section-actions">
			<a
				href=""
				class="mr-4"
				data-test-id="edit"
				@click.prevent="$emit('onEdit')"
			>
				<CIcon class="cil-pencil" name="cil-pencil" />
			</a>
			<a
				href=""
				class="mr-4"
				data-test-id="edit"
				@click.prevent="$emit('onDelete')"
			>
				<CIcon class="cil-trash" name="cil-trash" />
			</a>
		</div>
	</li>
</template>

<script>
export default {
	name: 'FooterSectionItem',
	props: {
		name: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.footer-section-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: rem(6) rem(12);
	background-color: $color-white;
	margin-bottom: rem(2);
	cursor: move;

	&:hover {
		background-color: $color-gray-200;
	}

	.footer-section-name {
		@include ellipsisOneline();

		max-width: rem(320);
		display: inline-block;
	}

	.cil-trash,
	.cil-pencil {
		color: $color-gray-400;

		&:hover {
			color: $color-gray-800;
		}
	}
}
</style>
