<template>
	<div>
		<BaseLoading v-if="isLoading && isUpdating" is-full-page />
		<main v-else class="main-wrapper">
			<!-- Service Section -->
			<CRow>
				<CCol md="7">
					<h6 data-test-id="title">
						Service Section
					</h6>
					<div class="description typo-helper-text color-black-45">
						Create footer item into each section to customize the footer.
					</div>
				</CCol>
				<CCol md="5" class="text-right">
					<CButton
						class="transparent"
						color="secondary"
						@click="$refs['modal-footer-item'].open('services', serviceId)"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Item
					</CButton>
				</CCol>
				<CCol md="12">
					<div class="footer-list mt-3" :class="!services.length ? 'd-flex align-items-center justify-content-center' : ''">
						<draggable
							v-if="services.length"
							:value="services"
							tag="ul"
							ghost-class="is-placeholder-item"
							class="footer-list-dragable"
							@input="handleServicesOrder"
						>
							<FooterSectionItem
								v-for="(data, index) in services"
								:key="index"
								:name="data.name"
								@onEdit="$refs['modal-footer-item'].open('services', serviceId, data, index)"
								@onDelete="handleOnDelete(data.id)"
							/>
						</draggable>
						<template v-else>
							<span class="subtitle color-black-45">No footer item in the section</span>
						</template>
					</div>
				</CCol>
			</CRow>
			<!-- End Service Section -->

			<!-- Application Section -->
			<CRow class="mt-3">
				<CCol md="7">
					<h6 data-test-id="title">
						Studio 7 Section
					</h6>
					<div class="description typo-helper-text color-black-45">
						Create footer item into each section to customize the footer.
					</div>
				</CCol>
				<CCol md="5" class="text-right">
					<CButton
						class="transparent"
						color="secondary"
						@click="$refs['modal-footer-item'].open('applications', applicationId)"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Item
					</CButton>
				</CCol>
				<CCol md="12">
					<div class="footer-list mt-3" :class="!applications.length ? 'd-flex align-items-center justify-content-center' : ''">
						<draggable
							v-if="applications.length"
							:value="applications"
							tag="ul"
							ghost-class="is-placeholder-item"
							class="footer-list-dragable"
							@input="handleApplicationsOrder"
						>
							<FooterSectionItem
								v-for="(data, index) in applications"
								:key="index"
								:name="data.name"
								@onEdit="$refs['modal-footer-item'].open('applications', applicationId, data, index)"
								@onDelete="handleOnDelete(data.id)"
							/>
						</draggable>
						<template v-else>
							<span class="subtitle color-black-45">No footer item in the section</span>
						</template>
					</div>
				</CCol>
			</CRow>
			<!-- End Application Section -->

			<!-- Infomation Section -->
			<CRow class="mt-3 mb-5">
				<CCol md="7">
					<h6 data-test-id="title">
						Infomation Section
					</h6>
					<div class="description typo-helper-text color-black-45">
						Create footer item into each section to customize the footer.
					</div>
				</CCol>
				<CCol md="5" class="text-right">
					<CButton
						class="transparent"
						color="secondary"
						@click="$refs['modal-footer-item'].open('infomation', infomationId)"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Item
					</CButton>
				</CCol>
				<CCol md="12">
					<div class="footer-list mt-3" :class="!infomation.length ? 'd-flex align-items-center justify-content-center' : ''">
						<draggable
							v-if="infomation.length"
							:value="infomation"
							tag="ul"
							ghost-class="is-placeholder-item"
							class="footer-list-dragable"
							@input="handleInfomationOrder"
						>
							<FooterSectionItem
								v-for="(data, index) in infomation"
								:key="index"
								:name="data.name"
								@onEdit="$refs['modal-footer-item'].open('infomation', infomationId, data, index)"
								@onDelete="handleOnDelete(data.id)"
							/>
						</draggable>
						<template v-else>
							<span class="subtitle color-black-45">No footer item in the section</span>
						</template>
					</div>
				</CCol>
			</CRow>
			<!-- End Infomation Section -->

			<!-- CTA -->
			<BaseActionPanelStickyFooter
				:disabled-confirm="isLoading && isUpdating"
				:disabled-cancel="true"
				content-class="main-wrapper"
				button-confirm-text="Save"
				@onConfirm="handleSubmit"
			/>

			<BaseModalConfirmDelete
				ref="modal-remove"
				:handle-remove="handleDelete.bind(null, deletedId)"
				title="Remove this footer item?"
				description="By removing this, the footer item will be disappeared from the list and linked items will be lost"
				@onSuccess="fetchFooters"
			/>

			<!-- modal footer item -->
			<ModalFooterItem
				ref="modal-footer-item"
				@onSuccess="fetchFooters"
			/>
		</main>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import FooterSectionItem from '@/components/FooterSectionItem.vue';
import ModalFooterItem from '@/components/ModalFooterItem.vue';

export default {
	name: 'FooterList',
	components: {
		draggable,
		FooterSectionItem,
		ModalFooterItem,
	},


	data() {
		return {
			isLoading: false,
			deletedId: null,

			applications: [],
			services: [],
			infomation: [],
		};
	},

	computed: {
		...mapState('footers', {
			list: 'list',
		}),

		...mapGetters({
			getStudio7List: 'footers/getStudio7List',
			getServiceList: 'footers/getServiceList',
			getInfomationList: 'footers/getInfomationList',
		}),

		isUpdating() {
			return this.list.isLoading;
		},

		applicationId() {
			return this.getStudio7List.id;
		},

		serviceId() {
			return this.getServiceList.id;
		},

		infomationId() {
			return this.getInfomationList.id;
		},
	},

	async created() {
		this.isLoading = true;

		await Promise.all([
			this.getFooters(),
			this.getFooterColumns(),
		]);

		this.applications = this.getStudio7List.items;
		this.services = this.getServiceList.items;
		this.infomation = this.getInfomationList.items;

		this.isLoading = false;
	},

	methods: {
		...mapActions({
			getFooters: 'footers/getFooters',
			handleDelete: 'footers/deleteFooterItem',
			handleSort: 'footers/sortFooters',
			getFooterColumns: 'pages/getFooterColumns',
		}),

		async fetchFooters() {
			await this.getFooters();

			this.applications = this.getStudio7List.items;
			this.services = this.getServiceList.items;
			this.infomation = this.getInfomationList.items;
		},

		// on sorting item
		handleApplicationsOrder(sortedList = []) {
			this.applications = sortedList;
		},

		handleServicesOrder(sortedList = []) {
			this.services = sortedList;
		},

		handleInfomationOrder(sortedList = []) {
			this.infomation = sortedList;
		},

		handleOnDelete(id) {
			this.deletedId = id;
			this.$refs['modal-remove'].open();
		},

		async handleSubmit() {
			this.isLoading = true;

			await this.handleSort({
				footer_columns: [
					{
						footer_column_id: this.applicationId,
						footer_ids: this.applications.map((item) => (item.id)),
					},
					{
						footer_column_id: this.serviceId,
						footer_ids: this.services.map((item) => (item.id)),
					},
					{
						footer_column_id: this.infomationId,
						footer_ids: this.infomation.map((item) => (item.id)),
					},
				],
			});

			this.isLoading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.footer-list {
	border: rem(2) solid $color-gray-100;
	background-color: $color-gray-100;
	border-radius: rem(4);
	list-style: none;
	min-height: rem(272);

	// .footer-list-dragable
	&-dragable {
		margin-bottom: 0;
		padding-left: 0;
	}
}
</style>
