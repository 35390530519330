<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			data-test-id="modal"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="currentData ? `Edit footer item modal` : title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CForm @submit.prevent="handleSubmit">
					<!-- TODO: hide for now -->
					<BaseDropDown
						v-model="column"
						:options="footerColumnOptions"
						:searchable="false"
						class="mt-2"
						track-by="value"
						label="name"
						label-drop-down="Footer section*"
						placeholder="Select footer select"
					/>
					<CInput
						ref="name"
						v-model.trim="$v.name.$model"
						:is-valid="!$v.name.$error && null"
						invalid-feedback="footer item is required"
						label="Footer item name*"
					/>
					<CRow>
						<CCol>
							<CInput
								ref="titleEN"
								v-model.trim="$v.titleEN.$model"
								:is-valid="!$v.titleEN.$error && null"
								invalid-feedback="Title (EN) is required"
								label="Title (EN)*"
							/>
						</CCol>
						<CCol>
							<CInput
								ref="titleTH"
								v-model.trim="$v.titleTH.$model"
								:is-valid="!$v.titleTH.$error && null"
								invalid-feedback="Title (TH) is required"
								label="Title (TH)*"
							/>
						</CCol>
					</CRow>
					<CInput
						ref="link"
						v-model.trim="$v.link.$model"
						:is-valid="!$v.link.$error && null"
						:invalid-feedback="$t('global.error.url')"
						label="Link*"
					/>
				</CForm>
			</template>
			<template #footer>
				<CButton
					:disabled="isSubmitting"
					class="modal-button transparent mr-3"
					color="tertiary"
					data-test-id="cancel-button"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					:disabled="$v.$invalid || isSubmitting"
					class="modal-button transparent"
					color="primary"
					data-test-id="primary-button"
					@click="handleSubmit"
				>
					{{ currentData ? 'Save changes' : 'Create' }}
				</CButton>
			</template>
		</CModal>
	</div>
</template>


<script>
import { mapActions, mapState } from 'vuex';
import { required, url } from 'vuelidate/lib/validators';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'ModalFooterItem',
	components: {
		ModalHeaderWrapper,
	},
	validations: {
		name: {
			required,
		},
		titleEN: {
			required,
		},
		titleTH: {
			required,
		},
		link: {
			required,
			url,
		},
	},
	props: {
		title: {
			type: String,
			default: 'Create footer item modal',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			isSubmitting: false,

			index: null,
			footerName: null,
			footerId: null,
			currentData: null,

			// form data
			id: 0,
			column: null,
			name: '',
			titleTH: '',
			titleEN: '',
			link: '',
		};
	},

	computed: {
		...mapState('pages', {
			footerColumn: 'footerColumn',
		}),

		footerColumnOptions() {
			return this.footerColumn.data.map((item) => ({ id: item.id, name: item.title_en, value: item.name }));
		},
	},

	methods: {
		...mapActions({
			handleCreate: 'footers/createFooterItem',
			handleUpdate: 'footers/updateFooterItem',
		}),

		open(footerName, footerId, data = {}, index = null) {
			this.footerName = footerName;
			this.footerId = footerId;

			// Find the first column in the footer column options that matches either the `footerId`
			const matchingColumn = this.footerColumnOptions.find(({ id }) => id === footerId);
			// If a matching column is found, set it as the `column` property
			if (matchingColumn) {
				this.column = matchingColumn;
			}

			if (data && index !== null) {
				this.index = index;
				this.currentData = data;

				this.initData();
			}

			this.isShow = true;
		},
		close() {
			this.resetState();
			this.isShow = false;
		},

		// form data
		initData() {
			if (!this.currentData) return;

			this.id = this.currentData.id;
			this.name = this.currentData.name;
			this.titleEN = this.currentData.titleEN;
			this.titleTH = this.currentData.titleTH;
			this.link = this.currentData.link;
		},
		resetState() {
			this.$v.$reset();

			this.index = null;
			this.footerName = null;
			this.footerId = null;
			this.currentData = null;

			// form data
			this.id = 0;
			this.column = null;
			this.name = '';
			this.titleTH = '';
			this.titleEN = '';
			this.link = '';
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) return;
			this.isSubmitting = true;

			try {
				const data = {
					footer_column_id: this.column.id,
					name: this.name,
					title_en: this.titleEN,
					title_th: this.titleTH,
					link: this.link,
					is_active: true,
					is_mobile_active: true,
				};

				if (this.currentData) await this.handleUpdate({ id: this.id, data });
				else await this.handleCreate({ data });

				this.$emit('onSuccess');
				this.close();
			} catch (e) {
				// do something
			}

			this.isSubmitting = false;
		},
	},
};
</script>
